import { checkmarkCircleOutline, closeOutline, paperPlaneOutline, repeatOutline } from 'ionicons/icons';

export const OFFSET_LIST_DEFAULT = 0;
export const MAX_32_BIT_SIGNED_INT = 2147483647;

export const QUOTATION_STATUS_ICON = {
  received: {
    icon: checkmarkCircleOutline,
    color: 'primary'
  },
  rejected: {
    icon: closeOutline,
    color: 'danger'
  },
  requested: {
    icon: paperPlaneOutline,
    color: 'light'
  },
  'pending approval': {
    icon: repeatOutline,
    color: 'primary'
  }
};

export const DEFAULT_WEIGHT_UOM = 'KG';
export const MAX_INPUT = 9999;
export const INPUT_QUANTITY_BY_WEIGHT = 1;
export const TOAST_COLOR = {
  PRIMARY: 'primary',
  DANGER: 'danger',
  BLACK: 'tertiary'
};

export const NUMBER_OF_DECIMAL_PLACES = 2;

export const USER_TYPE_ID = {
  ADMIN: 2,
  SALE: 7,
  BUYER: 6,
  SUB_BUYER: 12,
  SUPPLIER: 5
};
export const SUB_TYPE_NOTIFICATIONS = {
  NEW_PRODUCT: [1],
  PRICE_UPDATE: [2, 6, 13, 22, 16, 23],
  QUOTATION: [3, 4, 5],
  ORDER: [7, 8, 9, 10, 11, 12],
  PAYMENT: [30, 28, 29],
  ORDER_JOURNEY: [31, 32, 33],
  CUSTOMER_APPROVAL: [24],
  PRODUCT_STOCK_UPDATE: [34, 35, 36]
};

export const USER_TYPE = {
  SALE: [2, 5, 7],
  B2B: [1, 6, 12]
};

export const USER_COUNTRY = {
  SG: 193,
  MY: 127,
  INA: 96
};

export const CATEGORY_ENABLE_OOS = ['history', 'favourite', 'quoted'];

export const MERCHANT_APP_NAME = 'TreeDots for Merchants';
export const ACTION_BY_MERCHANT = 'Action by merchant user';
export const PAYMENT_UNSUCCESSFUL = 'Payment Unsuccessful';

export const ORDER_STATUS_NAME = {
  PROCESSING: 'Processing',
  PROCESSED: 'Processed',
  UPDATED: 'Updated',
  CANCELLED: 'Cancelled',
  DELIVERING: 'Delivering',
  DELIVERED: 'Delivered',
  FAILED_DELIVERY: 'Failed Delivery',
  DELIVERY_SUCCEED: 'Delivery Succeed', // deprecated. Only in older version of order logs
  DELIVERY_SUCCESSFUL: 'Delivery Successful',
  DELIVERY_STARTED: 'Delivery Started',
  ARRIVED: 'Arrived',
  COLLECTED: 'Collected',
  COMPLETED: 'Completed',
  BLOCKED: 'Blocked',
  AMENDED: 'Amended'
};

export const ORDER_STATUS_IDS = {
  PROCESSING: 1
  // add more if needed
};

export const ORDER_BLOCK_STATUS = {
  NOT_BLOCKED: 'not_blocked',
  LIMIT_BLOCKED: 'limit_blocked',
  UNCERTAINTY_BLOCKED: 'uncertainty_block'
};

export const ORDER_LOG_REASON_TYPE = {
  CUSTOMER_REQUEST: 'Customer Request',
  OUT_OF_STOCK: 'Out of Stock',
  OTHERS: 'Others'
};

export const ORDER_AMENDS_STATUS_NAME = {
  NEW: 'New',
  DELETED: 'Deleted',
  DELETE: 'Delete', // To accomodate different typing status from SD
  REPLACED: 'Replaced',
  REPLACE: 'Replace', // To accomodate different typing status from SD
  UPDATED: 'Updated'
};

export const SORTBY_FILTER_NAME = {
  NEWEST: 'newest',
  POPULAR: 'popular',
  NAME: 'name',
  PURCHASED: 'purchased'
};

export const TAB_CATEGORY = {
  HISTORY: 'history',
  FAVOURITE: 'favourite',
  BOOKINGS: 'bookings',
  ALL: 'all',
  NEW: 'new',
  QUOTED: 'quoted'
};

export const SEARCH_TAB_CATEGORY_ID = {
  RELEVANT: 0,
  NEWEST: 1
};

export const QUOTED_PRICED_BY = {
  WEIGHT: 'weight',
  OOM: 'oom'
};

export const IMAGE_PATH = {
  USER: 'profile-pictures',
  CUSTOMER: 'customer-pictures',
  PRODUCT: 'products',
  CATEGORY: 'category-images/new',
  CATEGORY_ICON: 'category-images/category-icons',
  CUISINES: 'cuisines'
};

export const DELIVERY_STATUS = {
  STARTED: 'STARTED',
  IN_PROGRESS: 'IN PROGRESS',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED'
};

export const QUOTE_RECEIVED = 'received';

export const DEFAULT_PRODUCT_IMAGE = '/assets/images/new-default-product-img.svg';
export const DEFAULT_APP_LOGO = '/assets/images/treedots_logo.svg';
export const DEFAULT_AVATAR = '/assets/images/no-avatar.png';
export const DEFAULT_USER_AVARTAR = '/assets/images/user.svg';
export const DEFAULT_NO_IMAGE = '/assets/images/no_image.png';
export const DEFAULT_NO_IMAGE_FOR_PRODUCT = '/assets/images/new-default-product-img.png';

export const LAT_LNG_SINGAPORE = { lat: 1.29027, lng: 103.851959 };
export const LAT_LNG_MALAYSIA = { lat: 3.168153, lng: 101.6695613 };
export const LAT_LNG_INDONESIA = { lat: -6.218363, lng: 106.836848 };
export const DEFAULT_ZOOM_GGMAP_FIRST_TIME = 13;
export const DEFAULT_ZOOM_GGMAP = 17;

export const SKU_PACKAGING_VARIANT = {
  IS_ORDER_BY_WEIGHT: 'IS_ORDER_BY_WEIGHT',
  IS_CATCH_WEIGHT: 'IS_CATCH_WEIGHT',
  REGULAR_VARIANT: 'REGULAR_VARIANT'
};
