import { DEFAULT_STATE } from '@/modules/sale/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const state = {
  ...DEFAULT_STATE,
  customer: null,
  joinStatus: null,
  list: {
    items: []
  },
  totalCount: 0,
  params: {
    isIncludeTotalCount: true,
    limit: 20,
    offset: 0,
    isLoadMore: false,
    searchQueries: '',
    tenantId: 0
  },
  customerBranch: null,
  customerConnect: null,
  customerView: null,
  saleGetCustomerDetail: {},
  customerImage: [],
  subBuyers: [],
  groupsAndCountriesAndDays: {},
  customerTenants: {},
  headCompany: {},
  selectedCustomer: null
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
